import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://beyondsnack.ca/";

class BeyondsnackApi {
  static async request(endpoint, data = {}, method = "get") {
    console.debug("API Call:", endpoint, data, method);

    const url = `${BASE_URL}${endpoint}`;
    const params = method === "get" ? data : {};

    try {
      return (await axios({ url, method, data, params })).data;
    } catch (err) {
      console.error("API Error:", err.response || err.message);
      let message =
        err.response?.data?.error?.message || "Something went wrong";
      throw Array.isArray(message) ? message : [message];
    }
  }

  /** Adds a new email for the signup
   * Takes user's name and email
   * Returns a message confirming the signup
   */
  static async addEmail(data) {
    const result = await this.request(`/api/signup`, data, "post");
    return result.message;
  }
}

export default BeyondsnackApi;
