import React, { useState } from "react";
import SignupForm from "./components/SignupForm";
import BeyondsnackApi from "./helper/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import backgroundVideo from "./videos/background.mp4";
import logo from "./images/BeyondSnack.png";

const App = () => {
  const [message, setMessage] = useState("");

  // Handle the signup submission
  const handleSignup = async (signupData) => {
    try {
      const result = await BeyondsnackApi.addEmail(signupData);
      setMessage(result); // Assuming result contains the proper message for successful or duplicate signup
      return { message: result }; // Return the message to display it in the SignupForm
    } catch (errors) {
      console.error("Signup failed", errors);
      setMessage("Signup failed. Please try again.");
      return { message: "Signup failed. Please try again." };
    }
  };

  return (
    <div className="App">
      <video className="background-video" autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="App-content">
        <header className="App-container">
          <img src={logo} alt="BeyondSnack Logo" className="logo" />
          <div lassName="App-header">
            <h1>BeyondSnack</h1>
            <small>Snack Smarter, Live Better.</small>
          </div>
        </header>
        <h1 className="app-text">
          The future of healthy snacking is coming...{" "}
          <i>Sign up to stay informed!</i>
        </h1>
        <SignupForm handleSignup={handleSignup} />
      </div>
    </div>
  );
};

export default App;
